<div class="container">

  <div class="row">
    <div class="col s6 offset-s3 z-depth-1" id="panell">

      <h5 id="title">QCloud 2.0 Password recovery</h5>
      <span *ngIf="!isTokenPresent" class="center-align" style="padding-left: 50px">Enter your email address and we
        will send a new password to your email</span>
      <span *ngIf="isTokenPresent" class="center-align" style="padding-left: 50px">Enter your new password</span>
      <form (ngSubmit)="onSubmit()" #recoveryForm="ngForm" ngNativeValidate>
        <div class="input-field" *ngIf="!isTokenPresent">
          <input type="email" name="username" [(ngModel)]="user.username" id="username" required email>
          <label for="username">Email</label>
        </div>
        <div class="input-field" *ngIf="isTokenPresent">
          <input type="{{ !showPassword ? 'password' : 'text' }}" name="password" [(ngModel)]="user.password"
            id="password" required>
          <label for="password">Password</label>
          <span class="helper-text show-password" (click)="showPassword = !showPassword">Show/hide password</span>
        </div>
        <button type="submit" class="right waves-effect waves-light btn" id="loginbtn"
          [disabled]="!recoveryForm.form.valid">Recover</button>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col s6 offset-s3 z-depth-0">
      <a routerLink="/register" id="registerLink" class="left greenLink">Sign up</a>
      <a id="recoverLink" routerLink="/login" class="right greenLink">Back to login</a>
    </div>
  </div>