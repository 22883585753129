import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { from } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AnnotationService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.headers = new HttpHeaders().set('Content-type', 'application/json');
        this.apiPrefix = environment.apiPrefix;
        this.annotationUrl = this.apiPrefix + 'api/troubleshooting/annotation';
    }
    addAnnotation(annotation) {
        const json = JSON.stringify(annotation);
        return this.httpClient.post(this.annotationUrl, json, { headers: this.headers });
    }
    getAnnotationsBetweenDates(datesArray, labSystemApiKey) {
        const currentDates = [];
        currentDates[0] = datesArray[0] + 'T00:00:00.000+02:00';
        currentDates[1] = datesArray[1] + 'T23:59:59.000+02:00';
        this.httpClient.get(this.annotationUrl + '/dates/' + currentDates[0] + '/' + currentDates[1] + '/' + labSystemApiKey)
            .subscribe((annotations) => {
            if (annotations !== null) {
                this.annotations$ = from([annotations]);
            }
        });
    }
    getAnnotationsBetweenDates2(datesArray, labSystemApiKey) {
        const currentDates = [];
        currentDates[0] = datesArray[0] + 'T00:00:00.000+02:00';
        currentDates[1] = datesArray[1] + 'T23:59:59.000+02:00';
        return this.httpClient.get(this.annotationUrl + '/dates/' + currentDates[0] + '/' + currentDates[1] + '/' + labSystemApiKey);
    }
    getAnnotationByLabSystemApiKeyAndDate(labSystemApiKey, date) {
        return this.httpClient.get(this.annotationUrl + '/labsystem/' + labSystemApiKey + '/' + date.toUTCString());
    }
    deleteAnnotation(annotation) {
        return this.httpClient.delete(this.annotationUrl + '/' + annotation.apiKey);
    }
    updateAnnotation(annotation) {
        const json = JSON.stringify(annotation);
        return this.httpClient.put(this.annotationUrl + '/' + annotation.apiKey, json, { headers: this.headers });
    }
    getAnnotationByApiKey(apiKey) {
        return this.httpClient.get(`${this.annotationUrl}/${apiKey}`);
    }
    getPage(pageToRequest, numberOfElements, selectedLsApiKey, startDate, endDate, troubleshootingName) {
        let params = new HttpParams();
        params = params.set('page', pageToRequest.toString()).set('size', numberOfElements.toString()); // paginator options
        params = params.set('lsApiKey', selectedLsApiKey);
        params = params.set('startDate', startDate);
        params = params.set('endDate', endDate);
        params = params.set('troubleshootingName', troubleshootingName);
        return this.httpClient.get(`${this.annotationUrl}/getPage`, { params: params });
    }
}
AnnotationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnnotationService_Factory() { return new AnnotationService(i0.ɵɵinject(i1.HttpClient)); }, token: AnnotationService, providedIn: "root" });
