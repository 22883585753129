import { Routes } from '@angular/router';
const ɵ0 = () => import("./../application/application.module.ngfactory").then(m => m.ApplicationModuleNgFactory), ɵ1 = () => import("./../entry-point/entry-point.module.ngfactory").then(m => m.EntryPointModuleNgFactory);
const routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'application', loadChildren: ɵ0 },
    { path: 'login', loadChildren: ɵ1 },
    { path: '**', redirectTo: '/login', pathMatch: 'full' }
];
export class RoutingModule {
}
export { ɵ0, ɵ1 };
