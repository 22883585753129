import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class NodeIntranetService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        // The url prefix
        this.apiPrefix = environment.apiPrefix;
        // The message URL
        this.nodeIntranetUrl = this.apiPrefix + 'api/intranet/node';
    }
    getAllNodes() {
        return this.httpClient.get(`${this.nodeIntranetUrl}/getAll`);
    }
    getNodeByApiKey(apiKey) {
        let params = new HttpParams();
        params = params.set('apiKey', apiKey);
        return this.httpClient.get(`${this.nodeIntranetUrl}/get`, { params: params });
    }
    getLabsystemsByNodeApiKey(nodeApiKey) {
        const params = new HttpParams().set('apiKey', nodeApiKey);
        return this.httpClient.get(`${this.nodeIntranetUrl}/getLS`, { params: params });
    }
    getLsStats(lsApiKey) {
        const params = new HttpParams().set('apiKey', lsApiKey);
        return this.httpClient.get(`${this.nodeIntranetUrl}/stats`, { params: params });
    }
    getLs(lsApiKey) {
        const params = new HttpParams().set('apiKey', lsApiKey);
        return this.httpClient.get(`${this.nodeIntranetUrl}/system`, { params: params });
    }
    getNodeByLsApiKey(lsApiKey) {
        const params = new HttpParams().set('apiKey', lsApiKey);
        return this.httpClient.get(`${this.nodeIntranetUrl}/getNodeByLsApiKey`, { params: params });
    }
    getNodeStats(nodeApiKey) {
        const params = new HttpParams().set('apiKey', nodeApiKey);
        return this.httpClient.get(`${this.nodeIntranetUrl}/statsNode`, { params: params });
    }
    getGeneralStats() {
        return this.httpClient.get(`${this.nodeIntranetUrl}/generalStats`);
    }
    getHomePageStats() {
        return this.httpClient.get(`${this.nodeIntranetUrl}/homePageStats`);
    }
}
NodeIntranetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NodeIntranetService_Factory() { return new NodeIntranetService(i0.ɵɵinject(i1.HttpClient)); }, token: NodeIntranetService, providedIn: "root" });
