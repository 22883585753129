/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bottom-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./bottom-modal.component";
import * as i3 from "../../common/modal.service";
var styles_BottomModalComponent = [i0.styles];
var RenderType_BottomModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BottomModalComponent, data: {} });
export { RenderType_BottomModalComponent as RenderType_BottomModalComponent };
export function View_BottomModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "modal bottom-sheet"], ["id", "modal2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["How many colums?"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "center-align"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "responsive-img column-selector"], ["src", "assets/images/one-column.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addColumn(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["class", "responsive-img column-selector"], ["src", "assets/images/two-columns.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addColumn(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "modal-footer"]], null, null, null, null, null))], null, null); }
export function View_BottomModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bottom-modal", [], null, null, null, View_BottomModalComponent_0, RenderType_BottomModalComponent)), i1.ɵdid(1, 245760, null, 0, i2.BottomModalComponent, [i3.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BottomModalComponentNgFactory = i1.ɵccf("app-bottom-modal", i2.BottomModalComponent, View_BottomModalComponent_Host_0, {}, {}, []);
export { BottomModalComponentNgFactory as BottomModalComponentNgFactory };
