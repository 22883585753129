import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LogoService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        // The url prefix
        this.apiPrefix = environment.apiPrefix;
        // The message URL
        this.logoUrl = this.apiPrefix + 'api/logo';
    }
    /**
     * @summary Sends a POST petition to the server to save the logo
     * @author Marc Serret
     * @since 1.0.0
     * @param {Logo} logo object to send to the server
     * @returns An observable with the saved logo
     * @access public
     */
    saveLogo(logo) {
        const json = JSON.stringify(logo);
        const params = json;
        const headers = new HttpHeaders().set('Content-type', 'application/json');
        return this.httpClient.post(this.logoUrl + '/save', params, { headers: headers });
    }
    /**
     * @summary Sends a GET petition to get all logos
     * @author Marc Serret
     * @since 1.0.0
     * @returns All the logos
     * @access public
     */
    getAllLogos() {
        return this.httpClient.get(this.logoUrl);
    }
    enableDisable(logo) {
        const headers = new HttpHeaders().set('Content-type', 'application/json');
        return this.httpClient.patch(`${this.logoUrl}/enableDisable`, JSON.stringify(logo), { headers: headers });
    }
    deleteLogo(logo) {
        return this.httpClient.delete(`${this.logoUrl}/${logo.apiKey}`);
    }
    getEnabledLogo() {
        return this.httpClient.get(`${this.logoUrl}/enabled`);
    }
    getByApiKey(apiKey) {
        return this.httpClient.get(`${this.logoUrl}/${apiKey}`);
    }
    updateLogo(logo) {
        const json = JSON.stringify(logo);
        const params = json;
        const headers = new HttpHeaders().set('Content-type', 'application/json');
        return this.httpClient.post(this.logoUrl + '/update', params, { headers: headers });
    }
}
LogoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LogoService_Factory() { return new LogoService(i0.ɵɵinject(i1.HttpClient)); }, token: LogoService, providedIn: "root" });
