/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./password-recovery.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "./password-recovery.component";
import * as i6 from "../../services/password-reset.service";
import * as i7 from "../../common/modal.service";
var styles_PasswordRecoveryComponent = [i0.styles];
var RenderType_PasswordRecoveryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PasswordRecoveryComponent, data: {} });
export { RenderType_PasswordRecoveryComponent as RenderType_PasswordRecoveryComponent };
function View_PasswordRecoveryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "center-align"], ["style", "padding-left: 50px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Enter your email address and we will send a new password to your email"]))], null, null); }
function View_PasswordRecoveryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "center-align"], ["style", "padding-left: 50px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Enter your new password"]))], null, null); }
function View_PasswordRecoveryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "input-field"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "input", [["email", ""], ["id", "username"], ["name", "username"], ["required", ""], ["type", "email"]], [[1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.user.username = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(3, 16384, null, 0, i2.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵdid(4, 16384, null, 0, i2.EmailValidator, [], { email: [0, "email"] }, null), i1.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i2.RequiredValidator, i2.EmailValidator]), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(7, 671744, null, 0, i2.NgModel, [[2, i2.ControlContainer], [6, i2.NG_VALIDATORS], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(9, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "label", [["for", "username"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Email"]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = ""; _ck(_v, 3, 0, currVal_8); var currVal_9 = ""; _ck(_v, 4, 0, currVal_9); var currVal_10 = "username"; var currVal_11 = _co.user.username; _ck(_v, 7, 0, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3).required ? "" : null); var currVal_1 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 9).ngClassValid; var currVal_6 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_PasswordRecoveryComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "input-field"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "input", [["id", "password"], ["name", "password"], ["required", ""]], [[8, "type", 0], [1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.user.password = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(3, 16384, null, 0, i2.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i2.RequiredValidator]), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(6, 671744, null, 0, i2.NgModel, [[2, i2.ControlContainer], [6, i2.NG_VALIDATORS], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(8, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "label", [["for", "password"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Password"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "helper-text show-password"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showPassword = !_co.showPassword) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Show/hide password"]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = ""; _ck(_v, 3, 0, currVal_9); var currVal_10 = "password"; var currVal_11 = _co.user.password; _ck(_v, 6, 0, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", (!_co.showPassword ? "password" : "text"), ""); var currVal_1 = (i1.ɵnov(_v, 3).required ? "" : null); var currVal_2 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 8).ngClassValid; var currVal_7 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_PasswordRecoveryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 16, "div", [["class", "col s6 offset-s3 z-depth-1"], ["id", "panell"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h5", [["id", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["QCloud 2.0 Password recovery"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PasswordRecoveryComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PasswordRecoveryComponent_2)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 9, "form", [["ngNativeValidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.onSubmit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 4210688, [["recoveryForm", 4]], 0, i2.NgForm, [[8, null], [8, null]], null, { ngSubmit: "ngSubmit" }), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(12, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PasswordRecoveryComponent_3)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PasswordRecoveryComponent_4)), i1.ɵdid(16, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "right waves-effect waves-light btn"], ["id", "loginbtn"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Recover"])), (_l()(), i1.ɵeld(19, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 6, "div", [["class", "col s6 offset-s3 z-depth-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 2, "a", [["class", "left greenLink"], ["id", "registerLink"], ["routerLink", "/register"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Sign up"])), (_l()(), i1.ɵeld(24, 0, null, null, 2, "a", [["class", "right greenLink"], ["id", "recoverLink"], ["routerLink", "/login"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 25).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(25, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Back to login"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isTokenPresent; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.isTokenPresent; _ck(_v, 8, 0, currVal_1); var currVal_9 = !_co.isTokenPresent; _ck(_v, 14, 0, currVal_9); var currVal_10 = _co.isTokenPresent; _ck(_v, 16, 0, currVal_10); var currVal_14 = "/register"; _ck(_v, 22, 0, currVal_14); var currVal_17 = "/login"; _ck(_v, 25, 0, currVal_17); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 12).ngClassValid; var currVal_7 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_11 = !i1.ɵnov(_v, 10).form.valid; _ck(_v, 17, 0, currVal_11); var currVal_12 = i1.ɵnov(_v, 22).target; var currVal_13 = i1.ɵnov(_v, 22).href; _ck(_v, 21, 0, currVal_12, currVal_13); var currVal_15 = i1.ɵnov(_v, 25).target; var currVal_16 = i1.ɵnov(_v, 25).href; _ck(_v, 24, 0, currVal_15, currVal_16); }); }
export function View_PasswordRecoveryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-password-recovery", [], null, null, null, View_PasswordRecoveryComponent_0, RenderType_PasswordRecoveryComponent)), i1.ɵdid(1, 245760, null, 0, i5.PasswordRecoveryComponent, [i4.ActivatedRoute, i4.Router, i6.PasswordResetService, i7.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PasswordRecoveryComponentNgFactory = i1.ɵccf("app-password-recovery", i5.PasswordRecoveryComponent, View_PasswordRecoveryComponent_Host_0, {}, {}, []);
export { PasswordRecoveryComponentNgFactory as PasswordRecoveryComponentNgFactory };
