import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TipService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        // The url prefix
        this.apiPrefix = environment.apiPrefix;
        // The message URL
        this.tipUrl = this.apiPrefix + 'api/tip';
    }
    /**
     * @summary Sends a POST petition to the server to save the tip
     * @author Marc Serret
     * @since 1.0.0
     * @param {tip} Tip object to send to the server
     * @returns An observable with the tip
     * @access public
     */
    saveTip(tip) {
        const json = JSON.stringify(tip);
        const params = json;
        const headers = new HttpHeaders().set('Content-type', 'application/json');
        return this.httpClient.post(this.tipUrl, params, { headers: headers });
    }
    /**
     * @summary Sends a GET petition to get all tips
     * @author Marc Serret
     * @since 1.0.0
     * @returns All the tips
     * @access public
     */
    getAllTips() {
        const headers = new HttpHeaders().set('Content-type', 'application/json');
        return this.httpClient.get(this.tipUrl, { headers: headers });
    }
    getAllActiveTips() {
        const headers = new HttpHeaders().set('Content-type', 'application/json');
        return this.httpClient.get(`${this.tipUrl}/active`, { headers: headers });
    }
    deleteTip(tip) {
        return this.httpClient.delete(`${this.tipUrl}/${tip.id}`);
    }
}
TipService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TipService_Factory() { return new TipService(i0.ɵɵinject(i1.HttpClient)); }, token: TipService, providedIn: "root" });
