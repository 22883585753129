import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LinkService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        // The url prefix
        this.apiPrefix = environment.apiPrefix;
        // The message URL
        this.linkUrl = this.apiPrefix + 'api/link';
    }
    /**
     * @summary Sends a GET petition to get all logos
     * @author Marc Serret
     * @since 1.0.0
     * @returns All the logos
     * @access public
     */
    getAllLinks() {
        return this.httpClient.get(this.linkUrl);
    }
    getByApiKey(apiKey) {
        return this.httpClient.get(`${this.linkUrl}/${apiKey}`);
    }
    updateLink(link) {
        const json = JSON.stringify(link);
        const params = json;
        const headers = new HttpHeaders().set('Content-type', 'application/json');
        return this.httpClient.post(this.linkUrl + '/update', params, { headers: headers });
    }
}
LinkService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LinkService_Factory() { return new LinkService(i0.ɵɵinject(i1.HttpClient)); }, token: LinkService, providedIn: "root" });
