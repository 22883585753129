/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/@ngx-progressbar/core/ngx-progressbar-core.ngfactory";
import * as i3 from "@ngx-progressbar/core";
import * as i4 from "@angular/router";
import * as i5 from "./modal-module/modal/modal.component.ngfactory";
import * as i6 from "./modal-module/modal/modal.component";
import * as i7 from "./common/modal.service";
import * as i8 from "./modal-module/bottom-modal/bottom-modal.component.ngfactory";
import * as i9 from "./modal-module/bottom-modal/bottom-modal.component";
import * as i10 from "./app.component";
import * as i11 from "@angular/platform-browser";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-progress", [["role", "progressbar"]], [[1, "spinnerPosition", 0], [1, "dir", 0], [1, "thick", 0], [1, "fixed", 0]], null, null, i2.View_NgProgressComponent_0, i2.RenderType_NgProgressComponent)), i1.ɵdid(1, 770048, null, 0, i3.NgProgressComponent, [i3.NgProgress], { color: [0, "color"], spinner: [1, "spinner"] }, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-modal", [], null, null, null, i5.View_ModalComponent_0, i5.RenderType_ModalComponent)), i1.ɵdid(5, 245760, null, 0, i6.ModalComponent, [i7.ModalService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-bottom-modal", [], null, null, null, i8.View_BottomModalComponent_0, i8.RenderType_BottomModalComponent)), i1.ɵdid(7, 245760, null, 0, i9.BottomModalComponent, [i7.ModalService], null, null)], function (_ck, _v) { var currVal_4 = "#a7d7c5"; var currVal_5 = false; _ck(_v, 1, 0, currVal_4, currVal_5); _ck(_v, 3, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).spinnerPosition; var currVal_1 = i1.ɵnov(_v, 1).direction; var currVal_2 = i1.ɵnov(_v, 1).thick; var currVal_3 = i1.ɵnov(_v, 1).fixed; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i10.AppComponent, [i11.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i10.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
